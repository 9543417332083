function generateRandomString(length) {
  //This is used for Rxcut, if using somewhere else where more combinations are needed, change the function to be flexible on allowed characters
  let chars = "23456789ABCDEFGHJKLMNPQRSTUVWXYZ";
  let result = "";
  for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
  return result;
}

$.urlParam = function (name, decode = true) {
  try {
    let results = new RegExp("[?&]" + name + "=([^&#]*)").exec(window.location.href);
    if (results) {
      results = results[1]; //we only want to use the value of name from results arr
      if (decode) {
        return decodeURI(results);
      } else {
        return results;
      }
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

$.urlParamFromString = function (url, name, decode = true) {
  try {
    let results = new RegExp("[?&]" + name + "=([^&#]*)").exec(url);
    if (results) {
      results = results[1]; //we only want to use the value of name from results arr
      if (decode) {
        return decodeURI(results);
      } else {
        return results;
      }
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

function loadGoogleMapsScript(environment) {
  let key;
  // use our key for dev/qa/test
  // use AES's key for staging/production
  switch (('' + environment).toLowerCase()) {
    case "test":
    case "development":
    case "qa":
      key = "AIzaSyDLkdmaaW4z7WRbnGwMTPI93xme929zd6g";
      break;
    case "staging":
    case "production":
    default:
      key = "AIzaSyB6Nat9kPwrs357yCZ4_8k3sbKiOMO0zXE";
      break
  }

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=Function.prototype`;
  document.head.appendChild(script);
}

function configureTelInputs(telFieldsObj, locale, isScreener = true) {
  //For any input, always check if this session already has a country code saved selected by user
  let currentCountryCode = sessionStorage.getItem("userTelCountryCode");
  if (!currentCountryCode || currentCountryCode == 'undefined') {
    currentCountryCode = locale && locale.length > 0 ? locale.split(/[^A-Za-z]/).pop() : "US"; //Account for multiple ways locale could be received in future. en_AU, en-AU, AU... Split locale by any non-alphabetic value & use last element in array.
    sessionStorage.setItem("userTelCountryCode", currentCountryCode.toLowerCase());
  }

  for (let index = 0; index < telFieldsObj.length; index++) {
    var iti = window.intlTelInput(telFieldsObj[index], {
      preferredCountries: [],
      initialCountry: currentCountryCode,
      nationalMode: true,
      separateDialCode: true,
      utilsScript: "/international-phone-input/js/utils.js",
      autoplaceholder: "off",
      customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
        return "";
      }
    });

    //UI mask for US
    telFieldsObj[index].addEventListener("countrychange", function (e) {
      const thisInputInstance = window.intlTelInputGlobals.getInstance(this);
      const inputsCountryData = thisInputInstance.getSelectedCountryData();

      if (inputsCountryData && Object.keys(inputsCountryData).length) {
        sessionStorage.setItem("userTelCountryCode", inputsCountryData.iso2);
        setTelephoneMask(this, inputsCountryData.iso2);
      }
    });

    setTelephoneMask(telFieldsObj[index], currentCountryCode);

    //Validation

    $(`<label id="phone-error-intl" class="error-intl-tel d-none" for="phone"></label>`).insertAfter(
      $(telFieldsObj[index]).parent()
    );

    $(telFieldsObj[index]).on("focusout", function () {
      if (!iti.isValidNumber() && iti.getSelectedCountryData().iso2 !== "us" && iti.getNumber()) {
        const translatedErrorsArr = getTranslatedPhoneStringArr(locale);
        $(this)
          .parent()
          .parent()
          .find("#phone-error-intl")
          .html(translatedErrorsArr[iti.getValidationError()])
          .removeClass("d-none");
        $(this).addClass("error-intl-tel");
      } else {
        $(this)
          .parent()
          .parent()
          .find("#phone-error-intl")
          .addClass("d-none");
        $(this).removeClass("error-intl-tel");
      }
      if (isScreener) {
        checkIfAllRequiredQuestionsAreAnswered();
      }
    });

    //CSS fix. It seems like a bug in the pluging where the padding added to the input is not wide enough to match the country dropdown's width
    $(telFieldsObj[index]).css("padding-left", "100px");
  }

  function getValidationMessage(errorCode) {
    const errorMap = [
      "Invalid number.",
      "Invalid country code.",
      "Number is too short.",
      "Number is too long.",
      "Invalid number."
    ];
    return errorMap[errorCode];
  }
}

function setTelephoneMask(object, countryCode) {
  countryCode = countryCode.toLowerCase();

  if (countryCode === "us") {
    Inputmask({
      mask: "(999) 999-9999",
      showMaskOnFocus: true,
      clearMaskOnLostFocus: true,
      autoUnmask: true
    }).mask(object);
    $(object).attr("placeholder", "(___) ___-____");
  } else {
    //IT WORKS BUT IT THROWS AN ERROR!
    $(object).inputmask("remove");
    $(object).removeAttr("data-im-insert");
    $(object).attr("placeholder", "");
  }
}

function formatTelNumberForApi(number, locale) {
  const currentCountryCode = locale && locale.length > 0 ? locale.split(/[^A-Za-z]/).pop() : "US"; //Account for multiple ways locale could be received in future. en_AU, en-AU, AU... Split locale by any non-alphabetic value & use last element in array.
  let formattedNumber = intlTelInputUtils.formatNumber(
    number,
    currentCountryCode,
    intlTelInputUtils.numberFormat.INTERNATIONAL
  );
  formattedNumber = formattedNumber.replace(/\D+/g, "");
  formattedNumber = "+" + formattedNumber;

  return formattedNumber;
}

function getDeviceWidth() {
  if (typeof (window.innerWidth) == 'number') {
    //Non-IE
    return window.innerWidth;
  } else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
    //IE 6+ in 'standards compliant mode'
    return document.documentElement.clientWidth;
  } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
    //IE 4 compatible
    return document.body.clientWidth;
  }
  return 0;
}

function scrollTo(position, speed) {
  $("html, body").clearQueue();
  $("html, body").animate(
    {
      scrollTop: position + "px"
    },
    speed
  );
}

function capitaliseFirst(text) {
  return text.replace(/^\w/, c => c.toUpperCase());
}

const isDecimal = n => !!(n % 1);

const showDecSigFig = n => (isDecimal(n) ? n : parseInt(n, 10));

function AutoTimeout(fn, delay) {
  this.delay = delay;
  this.stopped = true;

  this.restart = () => {
    if (!this.stopped) clearTimeout(this.timer);
    return this.timeout();
  };

  this.timeout = () => {
    this.timer = setTimeout(() => {
      this.stopped = true;
      fn.call(this, this.delay);
    }, this.delay);
    this.stopped = false;
    return this;
  };

  this.refresh = function () {
    if (!this.stopped) {
      clearTimeout(this.timer);
      return this.timeout();
    }
  };

  this.stop = function () {
    if (!this.stopped) clearTimeout(this.timer);
    this.stopped = true;
    return this;
  };

  this.start = function () {
    if (this.stopped) this.timeout();
    this.stopped = false;
    return this;
  };
}

function isSynexusCountry(locale) {
  return ["uk_UA", "pl_PL", "hu_HU", "bg_BG", "en_BG", "en_GB", "af_ZA", "en_ZA", "zu_ZA", "en_DE", "de_DE", "cs_CZ",
    "de_AT", "nl_BE", "hr_HR", "el_CY", "da_DK", "et_EE", "fi_FI", "fr_FR", "el_GR", "en_IE", "it_IT", "lv_LV",
    "lt_LT", "de_LU", "mt_MT", "nl_NL", "pt_PT", "ro_RO", "sk_SK", "sl_SI", "es_ES", "sv_SE", "he_IL", "ru_IL", "gu_IN", "hi_IN",
    "kn_IN", "kok_IN", "mr_IN", "pa_IN", "sa_IN", "ta_IN", "te_IN", "zh_CN", "zh_HK", "fr_BE", "de_BE", "en_BE", "en_HR",
    "el_CY", "et_EE", "fi_FI", "fr_FR", "el_GR", "it_IT", "ru_LV", "lt_LT", "fr_LU", "de_LU", "mt_MT", "en_MT", "pt_PT",
    "ro_RO", "sk_SK", "sl_SL", "sv_SV", "en_SV", "ru_IL", "iw_IL", "en_IL", "ar_IL", "en_IN", "hi_IN", "ua_UA", "ru_UA",
    "zh_CN", "zh_HK", 'ru_LT'].includes(locale);
}

function setTheme(locale, theme = null, category = null) {
  // restore theme - eg user left page and comes back it would previously show the blue aes theme
  if (!theme && sessionStorage.getItem('theme')) {
    theme = sessionStorage.getItem('theme');
  }
  let isSynexus = isSynexusCountry(locale);

  const validThemes = [
    "acurian-light", "acurian-dark", "synexus-dark", "synexus-light", 'ppd-eds', 'imbrium_gainme_ali1001', 'S11132','S11669','S11723','S12027','S12027P', 'S12027HP'
  ]

  let isValidTheme = validThemes.includes(theme);

  if (theme && isValidTheme) {
    isSynexus = theme.split("-")[0] === "synexus";
  }

  if ((!theme || theme == 'null') && isSynexus) {
    theme = 'synexus';
  }

  // store theme in session storage - this is incase the user leaves the page then comes back to an error screen. it would previously default to aes blue theme
  sessionStorage.setItem('theme', theme);

  // hide this for anything but en_US (if ppd it will be hidden below anyway)
  if (locale !== "en_US") {
    $('#footerLinksContainer .js-footerDoNotSell').hide();
  }

  if (theme === 'ppd-eds') {
    ppdFlag = true;

    $("body link#theme").attr("href", "/stylesheets/dist/themes/ppd-eds.min.css");
    $('.loader-gif').attr("src", "./../images/loading-ball-purple.gif");
    $('.ac-navbar').hide();
    $('.synexus-header').hide();
    $('.ppd-header').removeClass('d-none');
    $('#favicon16').attr("href", "./../images/ppd/ppd-favicon-16.png");
    $('#favicon32').attr("href", "./../images/ppd/ppd-favicon-32.png");
    $('[src="/images/illustrations/1.svg"]').hide();

    // set footer
    $('#footerLinksContainer .js-footerPrivacyPolicy').attr('href', 'https://www.ppd.com/who-we-are-/company-resources/privacy-policy');
    $('#footerLinksContainer .js-footerPrivacyPolicy').attr('data-translate-link-default', 'https://www.ppd.com/who-we-are-/company-resources/privacy-policy');
    $('#footerLinksContainer .js-footerDoNotSell').hide();
  }
  else {
    if (isSynexus) {
      synexusFlag = true;
      $("body link#theme").attr("href", "/stylesheets/dist/themes/synexus.min.css");
      //$('#questionCtaContainer').removeClass('row text-center').addClass('text-right');
      $('.loader-gif').attr("src", "./../images/loading-synexus.gif");
      $('#navbarHome').removeClass('text-center');
      $('.ac-navbar').hide();
      $('.synexus-header').removeClass('d-none');
      $('#favicon16').attr("href", "./../images/synexus/synexus-favicon-16.png");
      $('#favicon32').attr("href", "./../images/synexus/synexus-favicon-32.png");
    } else {
      $("body link#theme").attr("href", "/stylesheets/dist/themes/acurian.min.css");
    }
  }

  if (theme && isValidTheme) {
    $("body link#subtheme").attr("href", `/stylesheets/dist/themes/${theme}.min.css`);

    if (theme !== 'ppd-eds') {
      if (synexusFlag) {
        $('.synexus-header').addClass('d-none');
        $('.synexus-header-theme').removeClass('d-none');
      } else if(theme == 'imbrium_gainme_ali1001'){
        $('.ac-navbar').addClass('d-none');
        $('.imbrium_gainme_ali1001-header').removeClass('d-none'); 
        //$('.ac-navbar-theme').addClass('d-none');         
      } else if(theme == 'S11132'){
        $('.ac-navbar').addClass('d-none');
        $('.S11132-header').removeClass('d-none'); 
        //$('.ac-navbar-theme').addClass('d-none');
      } else if(theme == 'S11669'){
        $('.ac-navbar').addClass('d-none');
        $('.S11669-header').removeClass('d-none'); 
        //$('.ac-navbar-theme').addClass('d-none');
      } else if(theme == 'S11723'){
        $('.ac-navbar').addClass('d-none');
        $('.S11723-header').removeClass('d-none'); 
        //$('.ac-navbar-theme').addClass('d-none');
      } else if(theme == 'S12027'){
        $('.ac-navbar').addClass('d-none');
        $('.S12027-header').removeClass('d-none'); 
        //$('.ac-navbar-theme').addClass('d-none');
      } else if(theme == 'S12027P'){
        $('.ac-navbar').addClass('d-none');
        $('.S12027P-header').removeClass('d-none'); 
        //$('.ac-navbar-theme').addClass('d-none');
      }else if(theme == 'S12027HP'){
        $('.ac-navbar').addClass('d-none');
        $('.S12027HP-header').removeClass('d-none'); 
        //$('.ac-navbar-theme').addClass('d-none');
      } else {
        $('.ac-navbar').addClass('d-none');
        $('.ac-navbar-theme').removeClass('d-none');
      }
    }
  }

  if (category) {
    $("body link#category").attr("href", `/stylesheets/dist/themes/categories/${category}.min.css`);
    if (synexusFlag) {
      $('.synexus-header').addClass('d-none');
      $('.synexus-header-theme').removeClass('d-none');
    } else {
      $('.ac-navbar').addClass('d-none');
      $('.ac-navbar-theme').removeClass('d-none');
    }
  }
}

const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
  }
}

//'SMART' TRANSLATION HELPER FUNCTIONS WHICH FIND THE MOST SUITABLE LANGUAGE FROM M_LOCALE
const getTranslatedPhoneStringArr = (locale = "en_US") => {
  //returns whole array of all telephone validation translations
  if (telephoneValidationStrings[locale]) {
    return telephoneValidationStrings[locale];  //exact matching returned result
  } else {
    const allSupportedLocales = Object.keys(telephoneValidationStrings);
    const localeCountry = locale.split('_')[0];
    const localCountryRegex = RegExp(localeCountry);
    const applicableCountryLocaleString = allSupportedLocales[allSupportedLocales.findIndex(locale => localCountryRegex.test(locale))]; //string of a similar language in the same country
    if (applicableCountryLocaleString) {
      return telephoneValidationStrings[applicableCountryLocaleString]; //return another similar language of the same country
    } else {
      if (telephoneValidationStrings['en_US']) {
        return telephoneValidationStrings['en_US']; //we have US language, use this
      } else {
        return null; //worst case, we just dont have that translation name. Even in en_US
      }
    }
  }
};

// this one is used by site scheduler
const getTranslatedLanguageString = (type, locale = "en_US", name) => {
  //Helper function to get translated string content stored in translationStrings.js. Smart searching so if we don't match locale completely we still return a relevant language
  //type allowed to be: languageStrings, validatorLanguageStrings, tailSelectStrings

  if (window[type][locale] && window[type][locale][name]) { //ensure language exists before trying to access property
    //exact locale checking
    return window[type][locale][name];
  } else {
    //check for other same country regions
    const allCountryTranslations = Object.keys(window[type]); //every language available for this 'type'
    const localeCountry = locale.split('_')[0];
    const regex = RegExp(localeCountry);
    const matchingCountryLocale = allCountryTranslations[allCountryTranslations.findIndex(locale => regex.test(locale))]; //string of a similar language in the same country
    if (matchingCountryLocale && window[type][matchingCountryLocale][name]) {
      //return another similar language of the same country
      return window[type][matchingCountryLocale][name];
    } else {
      //try to return a default US
      if (window[type]['en_US'][name]) {
        return window[type]['en_US'][name]; //we have the en_US language
      } else {
        return null; //worst case, we just dont have that translation name. Even in en_US
      }
    }
  }
}

const getTranslatedErrorObj = (section, locale = "en_US") => {
  //slightly different structure to languageStrings as this will return an error object

  if (window.errorContentStrings[section] && window.errorContentStrings[section][locale]) { //ensure language exists before trying to access property
    return window.errorContentStrings[section][locale];
  } else {
    const allCountryTranslations = Object.keys(window.errorContentStrings[section]); //every language available for this 'type'
    const localeCountry = locale.split('_')[0];
    const regex = RegExp(localeCountry);
    const matchingCountryLocale = allCountryTranslations[allCountryTranslations.findIndex(locale => regex.test(locale))]; //string of a similar language in the same country

    if (matchingCountryLocale && errorContentStrings[section][matchingCountryLocale]) {
      //return another similar language of the same country
      return errorContentStrings[section][matchingCountryLocale];
    } else {
      //try to return a default US
      if (errorContentStrings[section]['en_US']) {
        return errorContentStrings[section]['en_US']; //we have the en_US language
      } else {
        return null; //worst case, we just dont have that translation. Even in en_US
      }
    }
  }
}

function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}



function formatNumber(number, decimalPlaces = 2) {
  number = number.toFixed(decimalPlaces); // gives eg 1.01
  number = number.replace('.00', ''); // remove .00
  number = parseFloat(number);
  return number;
}